
import React, { useEffect } from 'react';

const CidSelector = ({ cidOnClick,claimsList, cidParticipants, redLogo,focusOnCloseRef }) => {
  let isMounted = true;
  let options = {
    onProceed: function (authorisationServerId) {
      cidOnClick(authorisationServerId)
    },
    onError: function () {
      console.log('Error loading CID IDP Selector')
    },
    onParticipantsLoaded: function () {
      console.log("Participants loaded");
      Array.from(document.querySelectorAll("li[data-id]")).forEach(
        (participant) => {
          participant.addEventListener("keypress", (e) => {
            e.preventDefault(); // prevent scrolling down
            console.log("e.code", e.code);
            if (e.code == "Space") {
              participant.click();
            }
          });
        }
      );
    },
    buttonLabel: 'Verify',
    certificationStatus: 'All',
    rpLogoUrl: redLogo,
    participantsUri: cidParticipants,
    claims: claimsList,
    enableManualVerification: false,
    rpDataSharingDescription: "Your details will be shared for the purpose of verifying your identity with Telstra",
    inline: true,
    theme: "light"
  }
  
  useEffect(() => {
    if (isMounted) window.cidInitIdpSelector(options)
    isMounted = false
  },[isMounted])
  
  return <div ref={focusOnCloseRef} id="cid-idp-selector"></div>
}

export default CidSelector;