import React,{useState,useEffect,useRef} from "react";
import PageContent from "../PageContent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateAnalyticsData } from '../../helpers/analytics';
import { serverErrors, errorRefId } from '../../helpers/messages';
import { Grid, Column, TextStyle,ModalDialogConfirmation, ActionButton,useToggle } from "@able/react";
import { setSession,removeSessionItem, getSession } from "../../helpers";
import config from "../../helpers/config";
import redLogo from './../../assets/redLogo.svg';
import Loader from "../../components/Loader";
import CidSelector from "../../components/CidSelector"
import CustomSpacing from "../../components/Spacing";
import { IDPSelectorAuth } from '../../redux/api';
import { setPageLoaded } from "../../redux/verifyIdentifySlice.js";
const title = "Let’s verify your identity";

export default function PageConnectID() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { validateIDVResponse,isPageLoaded } = useSelector((state) => state.verifyIdentify);
  const claimsList = ["given_name", "middle_name", "family_name","birthdate"];
  const [modalIsOpen, toggleModal] = useToggle();
  const focusOnCloseRef = useRef(null);
  
  useEffect(() => {
    setTimeout(() => { setIsLoading(false) }, 1000)
    if (isPageLoaded) {
      navigation("/")
    }
  },[])

  const runIDPSelectorAuth = () => {
    const payload = {
      "authorisationServerId": getSession("par_authorisationServerId"),
      "essentialClaims": claimsList,
      "scanType": "Digital",
      "referenceId": validateIDVResponse.referenceId,
      "clientCode": validateIDVResponse.clientCode,
      "voluntaryClaims": [],
      "purpose": "verifying your identity"
    }
    dispatch(setPageLoaded(false));
    IDPSelectorAuth(payload)(dispatch)
    .then((response) => {
      if (response.message || !response.status || response.status === 401) {
        setSession("tokenExpired", true);
      } else {
        setSession("tokenExpired", false)
      }
      if (response.status === 200) {
        let sessionStore = {
          authorisationServerId:response.data.authorisationServerId,
          codeVerifier:response.data.codeVerifier,
          iss:response.data.iss,
          nonce:response.data.nonce,
          state:response.data.state,
          referenceId:response.data.referenceId
        }
        setSession("cid_store", sessionStore)
        window.location = response.data.authUrl
      } else {
        const eKey = "cidCreatePar";
        let err = null;
        let refCode = null;
        if(response.status === 500){
          err = "err_default";
        } else {
          err = "err_" + response.status + (response.errors ? "_" + response.errors[0].code : "");
        }
        refCode = errorRefId.prefix + errorRefId.delimeter + errorRefId["cidPar"] + serverErrors[err].errRefNo;
        dispatch(setCommonError({...serverErrors[eKey][err], refCode}))
        setSession("isCIDError",true);
        navigation("/confirm-identity");
      }
    })
    .catch((error) => {
      setSession("isCIDError",true);
      navigation("/confirm-identity");
    });
  }
  
  const cidOnClick = (authorisationServerId) => {
    setIsLoading(true)
    const participantName = document.querySelector(`li[data-id="${authorisationServerId}"]`).getAttribute("data-name");
    updateAnalyticsData(title, null, {
      "eventInfo": {
        "eventAction": "click",
        "eventType": "clickTrack",
        "eventCategory": "buttonClick",
        "eventName": participantName
      },
      "attributes":{}
    })
    setSession("par_authorisationServerId", authorisationServerId)
    updateAnalyticsData(title, null, {
      "eventInfo": {
        "eventAction": "impression",
        "eventType": "modelContent",
        "eventCategory": "information",
        "eventName": "Redirect to the bank's website or app - Open"
      },
      "attributes":{}
    })
    window.scrollTo(0, 0)
    setTimeout(() => {
      setIsLoading(false)
      toggleModal();
    }, 500);
  }

  const handleOnClick = (id, eventName) => {
    let eventAction = (id === "continue" || id==="cancel") ? "impression" : "click";
    let eventType = (id === "continue" || id==="cancel") ? "modelContent" : "clickTrack";
    let eventCategory = (id === "continue" || id==="cancel") ? "information" : "buttonClick";
    updateAnalyticsData(title, null, {
      "eventInfo": {
        "eventAction": eventAction,
        "eventType": eventType,
        "eventCategory": eventCategory,
        "eventName": eventName
      },
      "attributes":{}
    })
    if (id === "cancel") {
      const dataId = getSession("par_authorisationServerId");
      setTimeout(() => document.querySelector(`[data-id="${dataId}"]`).focus(),500)
    } else if (id === "continue") {
      setIsLoading(true)
      if (getSession("link_url") === "none") {
        setSession("qr_code_err",true)
        navigation("/confirm-identity")
      } else runIDPSelectorAuth()
    } else {
      removeSessionItem("cid_store")
      removeSessionItem("par_authorisationServerId")
      removeSessionItem("cid_code")
      removeSessionItem("cid_state")
      removeSessionItem("cid_iss")
      setTimeout(() => {
        navigation("/verify-identity")
      },500)
    }
  }

  return (
    <PageContent title={title} type="connectID">
      {
        isLoading ?
          <Loader /> :
          <div className="fullwidth">
            <CustomSpacing top="spacing4x" bottom="spacing4x">
              <div className="page-container">
                <TextStyle element="h1" alias="HeadingB">{title}</TextStyle>
              </div>
            </CustomSpacing>
            <div className="bg-gray">
              <CustomSpacing top="spacing3x" bottom="spacing3x">
                <div className="page-container">
                  <div className="fullwidth">
                    <Grid padding={false}>
                      <Column cols={12}>
                        <ModalDialogConfirmation
                          isShowing={modalIsOpen}
                          className="able-fonts"
                          setHideDialog={toggleModal}
                          title="Redirect to the bank's website or app"
                          description="By clicking 'continue', you'll be redirected to your selected organisation website or app. Telstra does not collect or store your ID document or any login or transaction details from your organisation when verifying via ConnectID."
                          stackButtonOnVXS={false}
                          confirmButtonLabel='Continue'
                          confirmButtonEvents={{ onClick: () => handleOnClick("continue", "Redirect to the bank's website or app - Continue") }}
                          cancelButtonLabel='Cancel'
                          cancelButtonEvents={{ onClick: () => handleOnClick("cancel", "Redirect to the bank's website or app - Cancel") }}
                          developmentUrl={window.location.origin + '/able-sprites.svg'}
                        />
                        <CidSelector
                          claimsList={claimsList}
                          cidParticipants={config.cidParticipants}
                          redLogo={redLogo}
                          cidOnClick={cidOnClick}
                          focusOnCloseRef={focusOnCloseRef}
                        />
                        <CustomSpacing bottom="spacing3x" right="spacing3x" left="spacing3x" className="whiteBg">
                          <TextStyle alias="FinePrintA">Telstra will only confirm your name and data of birth with your nominated organisation. We won’t access your banking details or government held information.</TextStyle>
                        </CustomSpacing>
                        </Column>
                    </Grid>
                  </div>
                  </div>
              </CustomSpacing>
            </div>
            <CustomSpacing top="spacing4x" bottom="spacing4x">
              <div className="page-container">
                <div className="fullwidth">
                  <CustomSpacing bottom="spacing1x">
                    <TextStyle element="p" alias="LabelA1">
                      Can't see a provider that works for you?
                    </TextStyle>
                  </CustomSpacing>
                  <ActionButton
                    developmentUrl={window.location.origin + "/able-sprites.svg"}
                    element="button"
                    label="Enter your details manually"
                    to="/"
                    type="button"
                    variant="LowEmphasis"
                    className="fullwidth"
                    events={{
                      onClick: () =>
                        handleOnClick("back", "Enter your details manually")
                    }}
                  />
                </div>
              </div>
            </CustomSpacing>
          </div>
      }
    </PageContent>
  );
}
